.SearchBar {
  width: 100%;
  position: fixed;
  top: 0;
  background: #f7f7f7;
  padding: 0.8rem;
  z-index: 10;

  &__Div {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #ffffff;
    padding: 1.2rem;

    &--Input {
      border: none;
      width: 100%;
      padding-left: 1rem;
      font-weight: normal;
      font-size: 1.8rem;
      line-height: 1.7rem;
      color: rgba(0, 0, 0, 0.6);
    }

    &--Input:focus {
      outline: none;
    }
  }
}
