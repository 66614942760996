.contentModal{
    display: flex;
    flex-direction: column;

}

.contentModal-heading{
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.6);
    margin: 0px;
    padding: 16px;
}

.contentModal-option{
    padding: 16px;
    display: grid;
    grid-gap:8px;
    grid-template-columns: repeat(2,1fr);
}

.contentModal-option-item{
    font-style: normal;
    font-weight: 450;
    font-size: 18px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.87);
    align-items: center;
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 16px 16px 70px 16px;
}


.contentModal-footer{
    background: #FFFFFF;
    border-top: 1px solid rgba(151, 151, 151, 0.247792);
    box-sizing: border-box;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contentModal-footer-btn{
    width: 100%;
    border: none;
    padding: 12px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    background: #009AE0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0350996);
    border-radius: 5px;
    color: #FFFFFF
}



//   .contentModal-radio {
//     // width: 2.2rem;
//     // height: 2.2rem;
//     position: absolute;
//     opacity: 0;
//   }

//   .contentModal-checkmark {
//     position: absolute;
//     top: 0;
//     right: 0;
//     width: 2.2rem;
//     height: 2.2rem;
//     background-color: #fff;
//     border-radius: 50%;
//     border: 1px solid #b1b1b1;
//   }

//   .contentModal-radio:checked ~ .contentModal-checkmark {
//     background-color: #ffffff;
//     border: 1px solid #2196f3;
//   }

//   .contentModal-checkmark:after {
//     content: "";
//     position: absolute;
//     display: none;
//   }

//   .contentModal-radio:checked ~  .contentModal-checkmark:after {
//     display: block;
//   }

//   .contentModal-checkmark:after {
//     top: 0.45rem;
//     left: 0.4rem;
//     width: 1rem;
//     height: 1rem;
//     border-radius: 50%;
//     background: #2196f3;
//   }