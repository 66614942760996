.FeaturePage {
  background: #e5e5e5;
  padding-top: 5.6rem;

  &__Details {
    margin: 2rem;
    padding: 1.6rem;
    font-weight: normal;
    font-size: 1.7rem;
    line-height: 150%;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    mix-blend-mode: normal;
    background: rgba(0, 154, 224, 0.12);
    border-radius: 4px;
  }

  &__Batches {
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 1.8rem;
    text-transform: uppercase;
    color: #777777;
    margin: 0 2rem 1.4rem;
  }

  &__BatchList {
    background: #ffffff;
    padding: 2rem;
    padding-bottom: 8rem;

    &--NoBatchesImg {
      position: absolute;
      // top: 20%;
      left: 50%;
      transform: translate(-50%, 20%);
    }
  }
}
