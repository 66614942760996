.BatchList {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.8rem;

  font-weight: normal;
  font-size: 2rem;
  line-height: 2.3rem;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.87;

  &__Radio {
    // width: 2.2rem;
    // height: 2.2rem;
    position: absolute;
    opacity: 0;
  }

  &__Checkmark {
    position: absolute;
    top: 0;
    right: 0;
    width: 2.2rem;
    height: 2.2rem;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #b1b1b1;
  }

  &__Radio:checked ~ .BatchList__Checkmark {
    background-color: #ffffff;
    border: 1px solid #2196f3;
  }

  &__Checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  &__Radio:checked ~ .BatchList__Checkmark:after {
    display: block;
  }

  &__Checkmark:after {
    top: 0.45rem;
    left: 0.4rem;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: #2196f3;
  }
}
