*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

html {
    scroll-behavior: smooth;
    // This defines what 1rem is
    font-size: 62.5%;

    @include respond(tab-land) {
        // width < 1200?
        font-size: 56.25%;
    }

    @include respond(tab-port) {
        // width < 900?
        font-size: 50%;
    }

    @include respond(big-desktop) {
        font-size: 75%;
    }
}

body {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
    margin: 0;
    padding: 0;
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    text-shadow: none;

    @include respond(tab-port) {
        padding: 0;
    }
}

::-moz-selection {
    background-color: $color-primary-blue;
    color: $color-primary-white;
}

::selection {
    background-color: $color-primary-blue;
    color: $color-primary-white;
}