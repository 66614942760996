.grid--1 {
  display: grid;
  grid-template-columns:
    [first-start]5rem [first-end second-start] minmax(50rem, 1.5fr)
    [second-end third-start]minmax(55rem, 1fr)
    [third-end fourth-start] 5rem [fourth-end];

  @include respond(tab-land) {
    grid-template-columns:
      [first-start]5rem [first-end second-start] minmax(60rem, 1.5fr)
      [second-end third-start]minmax(50rem, 1fr)
      [third-end fourth-start] 5rem [fourth-end];
  }

  @include respond(large-screen) {
    grid-template-columns:
      [first-start]5rem [first-end second-start] minmax(50rem, 1.5fr)
      [second-end third-start]minmax(50rem, 1fr)
      [third-end fourth-start] 5rem [fourth-end];
  }

  @include respond(medium-screen) {
    grid-template-columns:
      [first-start]5rem [first-end second-start] minmax(50rem, 1fr)
      [second-end third-start]minmax(40rem, 1fr)
      [third-end fourth-start] 5rem [fourth-end];
  }

  @include respond(tab-port) {
    grid-template-columns:
      [first-start]5rem [first-end second-start] minmax(40rem, 1fr)
      [second-end third-start]minmax(40rem, 1fr)
      [third-end fourth-start] 5rem [fourth-end];
  }

  @include respond(ipad) {
    grid-template-columns:
      [first-start]5rem [first-end second-start] minmax(40rem, 1fr)
      [second-end third-start]minmax(40rem, 1fr)
      [third-end fourth-start] 5rem [fourth-end];
  }

  @include respond(phone) {
    @include flexColumn;
  }
}

.grid--2 {
  display: grid !important;
  grid-template-columns: [first-start]1fr [first-end second-start] 1fr !important;
  grid-auto-flow: column !important;
  grid-column-gap: 5rem !important;

  @include respond(ipad) {
    @include flexColumn;
    align-items: center;
  }
}

.grid--3 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 4rem;

  @include respond(phone) {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 3rem;
    grid-column-gap: 1rem;
    // grid-gap: 4rem;
  }
}

.grid--4 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;

  grid-column-gap: 1rem;
  grid-row-gap: 4rem;

  @include respond(phone) {
    @include flexColumn;
  }
}

.grid--5 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  //  grid-column-gap: 29rem;

  @include respond(medium-screen) {
    grid-column-gap: 20rem;
  }

  @include respond(phone) {
    grid-column-gap: 1rem;
  }
}

.grid--6 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 23rem;
}

.grid--7 {
  display: grid;
  grid-template-columns: repeat(5, 8rem);
  grid-gap: 1rem;
  grid-template-rows: 4rem;
  align-items: center;
}

.grid--8 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5rem;
  grid-template-rows: 1fr 1fr;

  @include respond(phone) {
    grid-template-columns: 1fr;
  }
}

.grid--9 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3rem;

  @include respond(phone) {
    @include flexColumn;
  }
}

.link {
  padding: 1rem 3rem;
  text-decoration: none;
  font-size: 2rem;
  border-radius: 15px;

  &--1 {
    //  color: $color-blue-primary;
    //   background-color: $color-background-secondary;
  }

  &--2 {
    //   border: 1px solid $color-blue-primary;
    //  color: $color-blue-primary;
  }
}

.SVGfigure {
  height: 60rem;
  width: 60rem;
}

.forMargin {
  margin-left: 4rem;
}

.circle {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  align-self: center;

  &--1 {
    //background-color: $color-red-primary;
  }

  &--2 {
    //  background-color: $color-yellow-primary;
  }

  &--3 {
    // background-color: $color-green-primary;
  }
}

.spinnerDiv {
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.fw500 {
  font-weight: 500;
}

.blueStrip {
  width: 100%;
  background-color: $color-background-secondary;
  height: 2.4rem;
  display: none;

  @include respond(phone) {
    display: block;
    position: sticky;
    top: 0px;
  }
}

.cursor-free {
  cursor: pointer !important;
}

.marginTop13 {
  margin-top: 13rem;

  @include respond(phone) {
    margin-top: 0rem;
  }
}

.marginTop11 {
  margin-top: 11rem;

  @include respond(phone) {
    margin-top: 0rem;
  }
}

.marginTop7 {
  margin-top: 7rem;
}

.marginTop5 {
  margin-top: 5rem;
}

.marginTop3 {
  margin-top: 3rem;
}

.marginTop1 {
  margin-top: 1rem;
}

.marginLeft3 {
  margin-left: 3.8rem;

  @include respond(phone) {
    margin-left: 0;
    margin-bottom: 2.2rem;
  }
}

.marginTop2-4 {
  margin-top: 2.4rem;
}

.marginTop4-8 {
  margin-top: 4.8rem;
}

.colRev {
  flex-direction: column-reverse !important;
}

a {
  text-decoration: none;
  color: white;
}

.flexrow {
  @include flexRow;
}

.tutorsMobile {
  @include respond(phone) {
    margin-left: -2rem;
  }
}

.jcCheat {
  @include respond(phone) {
    justify-content: center;
  }
}

.p6rem {
  @include respond(phone) {
    padding: 0 6rem !important;
  }
}

.p2rem {
  @include respond(phone) {
    padding: 0 2rem !important;
  }
}

.funding {
  width: 80%;
  height: 100%;

  &--Phone {
    display: none;
    @include respond(phone) {
      display: block;
      width: 90%;
    }
  }

  &--Web {
    display: block;
    @include respond(phone) {
      display: none;
    }
  }
}
