.GoLiveButton {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 10;
  padding: 1.4rem;
  background: #ffffff;
  border: 1px solid rgba(151, 151, 151, 0.247792);
  box-sizing: border-box;

  &__Btn {
    width: 100%;
    background: #009ae0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0350996);
    border-radius: 5px;
    font-weight: 500;
    font-size: 2rem;
    line-height: 1.9rem;
    text-align: center;
    color: #ffffff;
    border: none;
    padding: 1.6rem 0;

    &--disabled {
      background: #dbdbdb !important;
    }
  }

  &__Btn:focus {
    outline: none;
  }
}
